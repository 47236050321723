@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";


html {
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "roboto";
}
body {
  background-color: #f5f5f5;
  background-position: top;
  background-size: 100% auto;
  background-repeat: repeat;
  background-attachment: fixed;
}
